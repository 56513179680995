<template>
  <div>
    <Contact v-bind:mobile="mobile"/>
    <FollowUs v-bind:mobile="mobile"/>
  </div>
</template>

<script>
  import Contact from '@/components/desktop/Contact'
  import FollowUs from '@/components/desktop/FollowUs'

  export default {
    name: 'ContactUs',
    props: ['mobile'],
    components: {
      Contact,
      FollowUs
    },
  }
</script>
