<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('joinUs') }}</h1>
    </v-row>
    <v-row justify="space-around" style="padding-bottom: 20px" no-gutters>
      <v-col v-bind:cols="mobile ? 12 : 4" v-for="(section, index) in sections" :key="index" v-bind:style="mobile ? 'padding-bottom: 40px;' : ''">
        <v-img
            :src="section.image"
            contain
            v-bind:height="mobile ? 100 : 175"
        />
        <h3 class="titleSection">
          {{ $t(section.title) }}
        </h3>
        <div class="textSection" v-for="(line, index) in section.text" :key="index">
          {{line}}
        </div>
      </v-col>
    </v-row>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3438.843998857138!2d7.048363209879949!3d43.55005350441414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12ce81b4a5e4c807%3A0xa342403c14df1bc9!2sSaint-Michel%20Valetta!5e0!3m2!1sfr!2sfr!4v1631052607205!5m2!1sfr!2sfr"
            width="100%"
            height="450"
            style="border:0; margin-bottom: -7px;"
            allowfullscreen=""
            loading="lazy">

    </iframe>
  </div>
</template>

<script>
  export default {
    name: 'Contact',
    props: ['mobile'],
    data: () => ({
      sections: [
        {
          image: require('@/assets/contact/location.svg'),
          title: 'sectionLocation',
          text: ['Résidence Saint Michel',  '69 Avenue du roi Albert 1er', '06 400 Cannes']
        },
        {
          image: require('@/assets/contact/mail.svg'),
          title: 'sectionMail',
          text: ['vitae.sportsante@gmail.com']
        },
        {
          image: require('@/assets/contact/phone.svg'),
          title: 'sectionPhone',
          text: ['06 33 73 41 61']
        }
      ]
    }),
  }
</script>

<i18n>
{
  "en": {
    "joinUs": "Join the network",
    "sectionLocation": "Our adress",
    "sectionMail": "Our email",
    "sectionPhone": "Our phone number"
  },
  "fr": {
    "joinUs": "Rejoignez le réseau",
    "sectionLocation": "Notre adresse",
    "sectionMail": "Notre mail",
    "sectionPhone": "Notre numéro"
  }
}
</i18n>

<style scoped>

.titleSection {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.textSection {
  text-align: center;
  color: #808080;
  font-family: Comfortaa, sans-serif;
}

</style>
